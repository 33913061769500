<template>
  <div v-if="cash_register_id != 0">
    <box v-if="cash_register_id != -1" :cashRegisterId="cash_register_id">
      <template slot="lockedButtons">
        <button
          class="btn btn-primary"
          :class="{ 'btn-block': $store.state.display.sm }"
          @click="
            $refs.dFormCashRegister.show();
            $refs.cbForm.reset();
          "
        >
          <i class="fa fa-file"></i> Abrir Nueva Caja
        </button>
      </template>
    </box>
    <div
      class="alert alert-warning"
      style="margin-top: 10px"
      role="alert"
      v-if="cash_register_id == -1"
    >
      No tienes caja registradora
    </div>
    <app-modal-basic ref="dFormCashRegister">
      <h4>FORMULARIO DE CAJA REGISTRADORA</h4>
      <cash-box-form
        ref="cbForm"
        @submitted="
          $refs.dFormCashRegister.hide();
          cash_register_id = $event;
        "
      ></cash-box-form>
    </app-modal-basic>
  </div>
</template>

<script>
import Box from "./Box";
import Form from "./Form";
import { AdminService } from "../AdminService";

export default {
  components: { Box, CashBoxForm: Form },
  data() {
    return {
      cash_register_id: 0
    };
  },
  created() {
    AdminService.getIdMyCashRegister().then(res => {
      if (res) {
        this.cash_register_id = res;
      } else {
        this.cash_register_id = -1;
      }
    });
  }
};
</script>

<style></style>
