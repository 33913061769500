<template>
  <form @submit.prevent="save()">
    <!-- <div class="form-group">
      <label for>Area *</label>
      <SelectArea ref="selectArea" v-model="reg.area_id"></SelectArea>
    </div> -->
    <!-- <div class="form-group">
      <label for>Fuente de Ingreso por Defecto *</label>
      <SelectSource
        ref="selectSource"
        :area_id="reg.area_id"
        v-model="reg.source_id"
      ></SelectSource>
    </div> -->
    <div class="form-group">
      <label for="">Entidades</label>
      <SelectEntity v-model="entity_id" ref="elSelEnt"></SelectEntity>
    </div>
    <div class="form-group">
      <label for>Responsable *</label>
      <SelectUser
        ref="selectUser"
        :disabled="!config.can_set_user"
        v-model="r_user_id"
      ></SelectUser>
    </div>
    <!-- <div class="form-group" v-show="config.set_initial_cash">
      <label for>Monto Inicial</label>
      <app-input-money
        :required="true"
        :quantity.sync="reg.initial_cash"
        :moneyCode.sync="reg.money_code"
        ref="inputMoney"
      ></app-input-money>
    </div> -->
    <div class="form-group">
      <button :disabled="readonly" class="btn btn-primary" type="submit">
        Guardar
      </button>
    </div>
  </form>
</template>

<script>
// import SelectArea from "../areas/Select";
import SelectUser from "../users/Select";
// import SelectSource from "../sources/Select";
import { AdminService } from "../AdminService";
import { mapState } from "vuex";
import SelectEntity from "../entitys/Select";

// let adminService = new AdminService();

export default {
  components: {
    // SelectArea,
    SelectUser,
    // SelectSource,
    SelectEntity
  },
  props: {
    readonly: {
      default: false
    }
  },
  data() {
    return {
      entity_id: 0,
      r_user_id: 0
    };
  },
  computed: {
    ...mapState({
      config: s => s.config.admin.cashregisters
    })
  },
  methods: {
    reset() {
      this.entity_id = 0;
      this.r_user_id = 0;

      // this.reg = {};
      // this.$refs.selectArea.setDefault();
      // this.$refs.inputMoney.setDefault();
      this.$refs.selectUser.setCurrentUser();
      // this.$refs.selectSource.setValueFromId(this.config.default_source_id);
      // this.$refs.selectSource.setDefault();
      this.$refs.elSelEnt.setCurentEntity();
    },
    save() {
      AdminService.saveCashRegister(this.$data).then(res =>
        this.$emit("submitted", res)
      );
    }
  }
};
</script>

<style></style>
