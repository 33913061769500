<template>
  <Multiselect
    v-model="val"
    :options="options"
    placeholder="Selecciona Uno"
    selectLabel="Presiona enter para seleccionar"
    deselectLabel="Presiona enter para deseleccionar"
    :loading="isLoading"
    @search-change="find"
    :internal-search="false"
    ref="multiselect"
    :disabled="disabled"
    @open="openHandler"
  >
    <template slot="singleLabel" slot-scope="props">{{
      props.option.name
    }}</template>
    <template slot="option" slot-scope="props">{{
      props.option.name
    }}</template>
    <span slot="noOptions">Sin resultados</span>
    <span slot="noResult">No hay resultados</span>
  </Multiselect>
</template>

<script>
// import Multiselect from "vue-multiselect";
import { AdminService } from "../AdminService";
import { debounce } from "lodash";

// let adminServ = new AdminService();

export default {
  // components: {
  //   Multiselect
  // },
  props: {
    disabled: {
      default: false
    },
    value: {
      default: undefined
    }
  },
  watch: {
    val(to) {
      if (to == null || to.id == undefined) {
        this.$emit("input", undefined);
      } else {
        this.$emit("input", to.id);
      }
    }
  },
  data() {
    return {
      isLoading: false,
      val: undefined,
      options: []
    };
  },
  created() {
    // AdminService.getUsers().then(list => (this.options = list.data));
  },
  methods: {
    openHandler() {
      if (this.options.length == 0) {
        AdminService.getUsers().then(list => (this.options = list.data));
      }
    },
    setValueFromId(user_id) {
      // if (source_id != null && source_id != undefined) {
      // if (source_id) {
      this.isLoading = true;
      AdminService.getUser(user_id).then(user => {
        this.isLoading = false;
        this.options = [user];
        this.val = user;
        this.$emit("input", user_id);
      });
      // } else {
      //   this.options = [null];
      //   this.val = null;
      // }
    },
    setCurrentUser() {
      this.setValueFromId(this.$store.state.user.id);
    },
    find(search) {
      this.asyncFind(search, this);
    },
    asyncFind: debounce((search, vm) => {
      vm.isLoading = true;
      AdminService.getUsers(search).then(list => {
        vm.options = list.data;
        vm.isLoading = false;
      });
    }, 350)
  }
};
</script>
